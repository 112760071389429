<template>
    <section v-if="selected_item">
        <div class="flex space-x-4">
            <div>
                <div
                    v-if="checked_created_show_budget_adgroup"
                    class="component-box mb-4"
                >
                    <p class="component-label">
                        {{ $t('common.budget_management') }}
                    </p>

                    <el-table class="w-full" :data="infor_adgroups_inline">
                        <el-table-column label="#" type="index" width="40" />

                        <el-table-column
                            min-width="120"
                            :label="$t('table.header.ad_group_name')"
                        >
                            <template #default="scope">
                                <div class="flex space-x-2">
                                    <div>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="scope.row.adgroup_name"
                                            placement="top"
                                        >
                                            <p
                                                class="font-semibold text-xs truncate"
                                            >
                                                {{ scope.row.adgroup_name }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                            min-width="120"
                            :label="$t('common.budget')"
                        >
                            <template #default="scope">
                                <el-input
                                    v-model="scope.row.budget"
                                    type="number"
                                    class="w-[120px]"
                                    size="small"
                                ></el-input>

                                <p class="component-text-desc">
                                    {{
                                        p__formatBudgetMode(
                                            scope.row.budget_mode
                                        )
                                    }}
                                </p>
                            </template>
                        </el-table-column>

                        <el-table-column
                            fixed="right"
                            width="180"
                            :label="$t('common.option')"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    :disabled="
                                        infor_adgroups_inline.length === 1 ||
                                        selected_item.extension.id ===
                                            scope.row.extension.id
                                    "
                                    size="small"
                                    @click="handleChooseItem(scope.$index)"
                                >
                                    {{ $t('button.choose') }}
                                </el-button>
                                <el-button
                                    v-if="infor_adgroups_inline.length > 1"
                                    type="danger"
                                    size="small"
                                    @click="handleRemoveItem(scope.$index)"
                                >
                                    {{ $t('button.delete') }}
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <EditAdGroupForm
                    :selected_item.sync="selected_item"
                    :currency="currency"
                    :timezone="timezone"
                    :tiktok_account_id="tiktok_account_id"
                    :advertiser_id="advertiser_id"
                    :min_budget_adgroup="min_budget_adgroup"
                    :belong_to_atosa="belong_to_atosa"
                    :bc_id="bc_id"
                    :objective_type="objective_type"
                    type_form="create"
                ></EditAdGroupForm>

                <div
                    class="component-box sticky bottom-0 top-0 flex border-bg border-t-[1px] -mt-2 z-40"
                >
                    <el-button
                        v-if="!created_campaign_id"
                        size="small"
                        class="mr-auto"
                        @click="m__step_inline = 1"
                    >
                        {{ $t('button.back') }}
                    </el-button>

                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-right"
                        class="ml-auto"
                        @click="handleNextStep"
                    >
                        {{ $t('button.continue') }}
                    </el-button>
                </div>
            </div>
            <div :class="[m__class_top]" class="h-fit min-w-[340px] w-[340px]">
                <div
                    class="flex flex-col space-y-4 w-full overflow-auto"
                    :style="{ height: `${height_select_box}px` }"
                >
                    <SelectAdgroupBox
                        class="w-full"
                        :items.sync="infor_adgroups_inline"
                        :infor_campaign="infor_campaign"
                        :selected_item.sync="selected_item"
                        :currency="currency"
                    ></SelectAdgroupBox>

                    <AvailableAudienceAdgroup
                        class="w-full"
                        :objective_type="objective_type"
                        :selected_item="selected_item"
                        :advertiser_id="advertiser_id"
                        :tiktok_account_id="tiktok_account_id"
                    ></AvailableAudienceAdgroup>

                    <CustomTargetAdgroupBox
                        class="w-full"
                        :tiktok_account_id="tiktok_account_id"
                        :objective_type="objective_type"
                        :selected_item.sync="selected_item"
                    ></CustomTargetAdgroupBox>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import createCampaignMixin from '../_mixin'
import EditAdgroupFormMixin from '@/components/orgranisms/edit-adgroup-form/_mixin'
import stickyTopMixin from '@/plugins/mixins/sticky-top'
import SelectAdgroupBox from './select-adgroup-box'

export default {
    components: {
        SelectAdgroupBox
    },

    mixins: [createCampaignMixin, EditAdgroupFormMixin, stickyTopMixin],

    props: [
        'checked_created_show_budget_adgroup',
        'infor_adgroups',
        'infor_campaign',
        'timezone',
        'bc_id',
        'min_budget_adgroup',
        'created_campaign_id'
    ],

    data() {
        return {
            selected_item: null,
            height_select_box: 680
        }
    },

    computed: {
        infor_adgroups_inline: {
            get() {
                return this.infor_adgroups
            },
            set(value) {
                this.$emit('update:infor_adgroups', value)
            }
        }
    },

    mounted() {
        this.selected_item = this.infor_adgroups_inline[0]

        const height = document.getElementById('view_layout')?.clientHeight || 0
        if (height > 0) {
            this.height_select_box = height - 20
        }
    },

    methods: {
        handleChooseItem(index) {
            this.selected_item = this.infor_adgroups_inline[index]
        },

        handleRemoveItem(index) {
            if (this.infor_adgroups_inline.length === 1) {
                return
            }

            if (
                this.selected_item.extension.id ===
                this.infor_adgroups_inline[index].extension.id
            ) {
                this.selected_item = this.infor_adgroups_inline[0]
            }
            this.infor_adgroups_inline.splice(index, 1)
        },

        handleNextStep() {
            let has_error = false

            for (const iterator of this.infor_adgroups_inline) {
                if (
                    !this.m__validAdgroup(
                        iterator,
                        this.min_budget_adgroup,
                        this.objective_type
                    )
                ) {
                    has_error = true
                    this.selected_item = iterator
                    return
                }
            }

            if (has_error) {
                return
            }

            this.m__step_inline = 3
        }
    }
}
</script>
